import React, { useState, useEffect, useRef } from 'react';
//import ReactDatePicker from 'react-datepicker';
import EDisclosureDoc from '../../img/termsAndConditions/e-disclosure_consent_statement.pdf';
import * as FullStory from '@fullstory/browser';
import './acceptForm.scss';
//import './condensed_sections.css';
import './additional_acceptForm.scss';
//import './react-datepicker.scss';

const AcceptForm = (props) => {

    const TCDoc = process.env.PUBLIC_URL + "files/tc-sky2-O207.pdf";
    const TCPage1 = process.env.PUBLIC_URL + "files/tc-sky2-O207-1a.svg";
    const TCPage2 = process.env.PUBLIC_URL + "files/tc-sky2-O207-2a.svg";
    const TCPage3 = process.env.PUBLIC_URL + "files/tc-sky2-O207-3a.svg";
    const capBankPrivacyPolicyUrl = "https://capitalbankmd.com/capital-bank-privacy-policy";

    const ssnInput = useRef(null);
    const currentCardInput = useRef(null);
    const dateOfBirthInput = useRef(null);
    const dateOfBirthInputText = useRef(null);

    const [fieldErrors, setFieldErrors] = useState({});
    const [formData, setFormData] = useState({
        DateOfBirth: '',
        Last4SSN: '',
        CurrentCardEndingDigits: '',
        IsTcAccepted: null,
        IsTcAccepted2: null
    });
    const [dateInputSupported, setDateInputSupported] = useState(false); // FALSE WHEN DEBUGGING

    const handleValidation = (fieldName) => {
        let errors = { ...fieldErrors };
        if (formData[fieldName] === '') {
            errors[fieldName] = <div>Oops, it looks like something may be missing<br />Please double check all of the fields are completed.</div>;
        } else if (!inputValidation[fieldName].RegEx.test(formData[fieldName])) {
            //console.log('validation test ' + fieldName + ' ' + formData[fieldName]);
            errors[fieldName] = inputValidation[fieldName].ValidationErrorText;
        } else {
            delete errors[fieldName];
        }
        return errors;
    };

    const getErrors = () => {
        let errors = {};
        errors = { ...errors, ...handleValidation("DateOfBirth") };
        errors = { ...errors, ...handleValidation("Last4SSN") };
        errors = { ...errors, ...handleValidation("IsTcAccepted") }
        errors = { ...errors, ...handleValidation("IsTcAccepted2") }
        return errors;
    }

    useEffect(() => {
        let errors = {};
        if (formData.IsTcAccepted !== null) {
            let newErrors = handleValidation("IsTcAccepted")            
            errors = { ...errors, ...(newErrors["IsTcAccepted"] !== undefined ? { IsTcAccepted: newErrors["IsTcAccepted"] } : false) };
        }
        if (formData.IsTcAccepted2 !== null) {
            let newErrors = handleValidation("IsTcAccepted2")
            errors = { ...errors, ...(newErrors["IsTcAccepted2"] !== undefined ? { IsTcAccepted2: newErrors["IsTcAccepted2"] } : false) };
        }
        setFieldErrors(errors);
    }, [formData.IsTcAccepted, formData.IsTcAccepted2])

    const inputValidation = Object.freeze({
        DateOfBirth: {
            RegEx: /^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}$/,
            ValidationErrorText: ("Please check Date of Birth." + (!dateInputSupported ? " (MM/DD/YYYY)" : "")),
            //RegEx: /(19|20)\d\d[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])/,
            //ValidationErrorText: ("Please check Date of Birth." + (!dateInputSupported ? " (YYYY-MM-DD)" : "")),
        },
        Last4SSN: {
            RegEx: /^[0-9\b]{4}$/,
            ValidationErrorText: "Please enter valid last 4 digits of you SSN.",
        },
        IsTcAccepted: {
            RegEx: /true/,
            ValidationErrorText: "Please read and accept Terms & Conditions to continue.",
        },
        IsTcAccepted2: {
            RegEx: /true/,
            ValidationErrorText: "Please accept electronic disclosures.",
        }
    });

    const handleTcAcceptedChange = event => {
        setFormData({ ...formData, IsTcAccepted: !formData.IsTcAccepted });
    }

    const handleTcAccepted2Change = event => {
        setFormData({ ...formData, IsTcAccepted2: !formData.IsTcAccepted2 });
    }
    const formatDate = (dateObject) => {
        let year = dateObject.getFullYear();
        let month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
        let day = dateObject.getDate().toString().padStart(2, '0');

        return year + '-' + month + '-' + day;
    }
    const formatUSDate = (dateObject) => {
        let parts = dateObject.split('-');
        let year = parseInt(parts[0]);
        let month = parseInt(parts[1]);
        let day = parseInt(parts[2]);


        return month + '/' + day + '/' + year;
    }


    const handleChange = event => {
        let { name, value } = event.target;

        if (name == "DateOfBirth") {
            value = value.replace(/[^0-9\/]/g, '');
        }

        setFormData({ ...formData, [name]: value });
    }
    const correctDob = () => {
        let value = dateOfBirthInput.current.value;
        let dateRegex = /^\d{1,2}\/\d{1,2}\/\d{4}$/;
        if (dateRegex.test(value)) {
            let parts = value.split('/');
            let month = parseInt(parts[0]) - 1;
            let day = parseInt(parts[1]);
            let year = parseInt(parts[2]);
            let correctedDob = (new Date(year, month, day))
            value = formatDate(correctedDob);
            //console.log('Sending DobValue:' + value);
            //formData["DateOfBirth"] = value;
        }
        return value;
    }
    const handleSubmit = event => {
        event.preventDefault();


        FullStory.event('GoldTermsSubmit', {});
        const validationErrors = validateForm();
        setFieldErrors(validationErrors);
        if (Object.keys(validationErrors).length === 0) {
            formData["DateOfBirth"] = correctDob();
            props.onFormSubmit(formData);
            formData["DateOfBirth"] = formatUSDate(formData["DateOfBirth"]);
        }
    }

    const isFormValid = () => {
        const validationErrors = getErrors();
        return (Object.keys(validationErrors).length === 0);
    }

    const validateForm = () => {
        let errors = {};

        errors = { ...errors, ...handleValidation("DateOfBirth") };
        if (errors["DateOfBirth"]) {
            dateOfBirthInput.current.focus();
        }

        errors = { ...errors, ...handleValidation("Last4SSN") };
        if (errors["Last4SSN"]) {
            ssnInput.current.focus();
        }

        errors = { ...errors, ...handleValidation("IsTcAccepted") };
        errors = { ...errors, ...handleValidation("IsTcAccepted2") };

        return errors;
    };

    const isDateInputSupported = () => {
        var elem = document.createElement('input');
        elem.setAttribute('type', 'date');
        elem.value = 'not-a-date';

        //return (!elem.value !== 'note-a-date');
        return false; // FOR DEBUGGING
    };


    useEffect(() => {

        setDateInputSupported(isDateInputSupported());

        //console.log('IsDateSupported:', isDateInputSupported());
        //console.log('DateInputSupported:', dateInputSupported);

    }, []);



    return (
        <>
            <span ref={props.formTopRef}></span>
            <div className="container the-form">
                {/*<h2>Yes, it&rsquo;s me!</h2>*/}

                <form onSubmit={handleSubmit}>
                    <div className="top-part">
                        <p className="text-left required-text"><br className="mobile" />*Indicates required fields.</p>
                        <div className="FirstName">
                            <input
                                type="text"
                                placeholder="First Name"
                                id="FirstName"
                                name="FirstName"
                                value={props.firstName}
                                readOnly
                            />
                        </div>
                        <div className="LastName">
                            <input
                                type="text"
                                placeholder="Last Name"
                                id="LastName"
                                name="LastName"
                                value={props.lastName}
                                readOnly
                            />
                        </div>
                        <div className="EmailAddress">
                            <input
                                type="text"
                                placeholder="Email Address"
                                id="EmailAddress"
                                name="EmailAddress"
                                value={props.emailAddress}
                                readOnly
                            />
                        </div>
                        <div className="DateOfBirth">
                            <input
                                type="text"
                                placeholder="Date of Birth (MM/DD/YYYY)*"
                                id="DateOfBirth"
                                name="DateOfBirth"
                                onChange={handleChange}
                                ref={dateOfBirthInput}
                                value={formData.DateOfBirth}

                                className={fieldErrors["DateOfBirth"] ? 'input-error' : ''}
                                onBlur={(event) => {
                                    setFieldErrors(handleValidation(event.target.name));
                                    if ((event.target.value ?? '') === '') {
                                        event.target.type = 'text';
                                    }
                                }}
                                onFocus={(event) => {
                                    if (dateInputSupported) {
                                        event.target.type = 'date';
                                    }
                                }}
                            />
                            {fieldErrors.DateOfBirth
                                ? <span className="span-error">{fieldErrors.DateOfBirth}</span>
                                : null
                            }
                        </div>

                        <div className="ssn">
                            <input
                                type="text"
                                placeholder="Last 4 Digits of SSN*"
                                id="Last4SSN"
                                name="Last4SSN"
                                minLength="4"
                                maxLength="4"
                                onChange={handleChange}
                                className={fieldErrors["Last4SSN"] ? 'input-error' : ''}
                                ref={ssnInput}
                                value={formData.Last4SSN}
                                onBlur={(event) => setFieldErrors(handleValidation(event.target.name))}
                            />
                            {fieldErrors.Last4SSN
                                ? <span className="span-error">{fieldErrors.Last4SSN}</span>
                                : null
                            }
                        </div> 
                        <div className="toc-continue">To continue, please review and approve the terms below</div>
                        <div className="toc-body card">
                            <div className="card-body">
                                <div className="label-disclosures">
                                    <span>Important Disclosures </span>
                                    <a
                                        variant="link"
                                        href={TCDoc}
                                        target="_blank"
                                    >
                                        Download/Print
                                    </a>
                                </div>
                            </div>

                            <div className="toc-rates">
                                <div className="content">
                                    <div className="legal">
                                        <img src={TCPage1} alt="Terms and Conditions document" />
                                        <img src={TCPage2} alt="Terms and Conditions document" />
                                        <img src={TCPage3} alt="Terms and Conditions document" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="terms">
                        <div className="review">Review and agree to terms</div>
                        <div className="row">                            
                            <div className="col text-justify">
                                {fieldErrors.IsTcAccepted
                                    ? <span className="span-error">{fieldErrors.IsTcAccepted}</span>
                                    : null
                                }
                                <div className="review-condition">
                                    <input
                                        type="checkbox"
                                        name="IsTcAccepted"
                                        onChange={handleTcAcceptedChange}
                                        className="formcheck"
                                        defaultChecked={formData.IsTcAccepted} />
                                    <div className="ml-2">
                                        I agree to OpenSky's <a className="terms-links" href={TCDoc} target="_blank">Terms and Conditions</a>, <a className="terms-links" href={TCDoc} target="_blank">Important Disclosures</a>, and <a className="terms-links" href={capBankPrivacyPolicyUrl} target="_blank">Privacy and Opt Out Notice</a>, and consent to receive by electronic means only, important account information, that includes but is not limited to rates, fees, costs, account statements, and other communications.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            
                            <div className="col text-justify">
                                {fieldErrors.IsTcAccepted2
                                    ? <span className="span-error">{fieldErrors.IsTcAccepted2}</span>
                                    : null
                                }
                                <div className="review-condition">
                                    <input
                                        type="checkbox"
                                        name="IsTcAccepted2"
                                        onChange={handleTcAccepted2Change}
                                        className="formcheck"
                                        defaultChecked={formData.IsTcAccepted2} />
                                    <div className="ml-2">
                                        I understand that these electronic disclosures may not be available in any paper or non-electronic form. <a className="terms-links" href={EDisclosureDoc} target="_blank">Click here</a> to access the detailed electronic disclosures and a description of the necessary system requirements.
                                    </div> 
                                </div>
                               
                            </div>
                        </div>
                        <p align="center">
                            <button type="submit" className="btn-submit" disabled={!isFormValid()}>Submit</button>
                        </p>
                    </div>
                </form>
            </div>
        </>
    );
}

export default AcceptForm