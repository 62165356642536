import React, { useState, useEffect } from "react";
import openskyLogo from '../../img/opensky-black-no-trademark.svg';
import './footer.css';
import * as FullStory from '@fullstory/browser';

const Footer = (props) => {
    const [width, setWidth] = useState(window.innerWidth);
    const formTopRef = props.formTopRef;
    const CHADoc = process.env.PUBLIC_URL + "files/ca-sky2-O207.pdf";

    const scrollToFormTop = () => {
        FullStory.event('GoldFooterAccept', {});        
        formTopRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        formTopRef.current.focus();
    };    
    window.addEventListener('resize', function (event) {
        setWidth(window.innerWidth)
    });

    
    //useEffect(() => {
        
    //    var acceptButton = document.getElementById("footerButton");
    //    if (width<=600) {
    //        if (!acceptButton.classList.contains("w-100")) {
    //            acceptButton.classList.add("w-100");
    //        }
    //        else {
    //            if (acceptButton.classList.contains("w-100")) {
    //                acceptButton.classList.remove("w-100");                    
    //            }
    //        }
    //    }
    //    else {
    //        if (acceptButton.classList.contains("w-100")) {
    //            acceptButton.classList.remove("w-100");
    //        }
    //    }
    //}, [setWidth, window.innerWidth]);
    
    //TODO: create copyright blurb
    return (
        <footer>
            <section className="footer-section">
                <div className="footer-section-content">
                    <div className="footer-logo">
                        <div className="container">

                            <div className="d-flex flex-column flex-sm-row w-100 gap-2">
                                <div className="col mt-2">
                                    <a href="./">
                                        {/* TODO: set this width elsewhere */}
                                        <img src={openskyLogo} className="img-fluid"
                                            alt="OpenSky" />
                                    </a>
                                </div>
                                <div className="col mt-2">
                                    <div className="footer-right text-right">
                                        {!props.hideButton && (
                                            <button id="footerButton"
                                                className="get-started gradient-border-transparent"
                                                onClick={() => scrollToFormTop()}
                                            >
                                                Accept your card today
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="disclosure-statement-container">
                        <p className="disclosure-statement-text">* OpenSky Cash Back Rewards are redeemed on your OpenSky credit card through statement credits. Typically within 120 days after a qualified transaction has been processed, a statement credit will be automatically issued to your OpenSky Secured Visa®, OpenSky Plus Secured Visa, or OpenSky Visa Gold credit card. If you do not receive cash back within this timeframe, please submit an inquiry form in our rewards website, which can be accessed by logging into your account or mobile app and clicking on OpenSky Rewards.</p>
                    </div>
                    <div className="footer-copy-links">
                        <div className="copyright">
                            <p className="helper">
                                <span id="copyright">Copyright &copy; {new Date().getFullYear()} OpenSky. All rights reserved.</span>
                            </p>
                        </div>
                        <div className="legal-links">
                            <p className="helper"><a href="https://capitalbankmd.com/capital-bank-privacy-policy/">Privacy</a></p>
                            <p className="helper"><a href="https://openskycc.com/security">Security</a></p>
                            <p className="helper"><a className="basicCardHolderAgreement" href={CHADoc}>Cardholder Agreement</a></p>
                        </div>
                    </div>
                </div>
            </section>
        </footer>
    );
};

export default Footer;